import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import throttle from "lodash/throttle";
import { Typography } from "app/components/generics/Typography";
import { isEmpty, maybeObject } from "utils/functions.utils";
import {
  faCircleExclamation,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

type SizeType = "xs" | "sm" | "md" | "lg" | "xl" | undefined;
type OrientationType = "vertical" | "horizontal";
type FilterType = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => boolean;

interface IBaseInput {
  size?: SizeType;
  error?: string | boolean;
  message?: any;
  hint?: any;
  block?: boolean;
  isOptional?: boolean;
  insetMaxLength?: boolean;
  allowClear?: boolean;
  footer?: any;
  loading?: boolean;
  label?: string;
  labelFor?: string;
  autoComplete?: string;
  register?: any;
  labelProps?: any;
  hintProps?: any;
  messageProps?: any;
  orientation?: OrientationType;
  filter?: RegExp | FilterType;
  style?: React.CSSProperties;
}

interface IBaseTextArea extends IBaseInput {
  // rows?: number | { min?: number; max?: number };
}

interface IInput
  extends IBaseInput,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {}

interface ITextArea
  extends IBaseTextArea,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "size"> {
  resize?: boolean;
  fill?: boolean;
}

interface ICompoundedInputComponent
  extends React.ForwardRefExoticComponent<
    IInput & React.RefAttributes<HTMLInputElement>
  > {
  Label: any;
  Hint: any;
  TextArea: React.ForwardRefExoticComponent<
    ITextArea & React.RefAttributes<HTMLTextAreaElement>
  >;
}

const Label = (props) => {
  const { children, labelFor, className = "", ...rest } = props;
  return (
    <Typography
      flat
      as="label"
      htmlFor={labelFor || children}
      /*bold*/ className={`${className}`}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const Hint = (props) => {
  const { children, type = "secondary" } = props;
  return typeof children == "string" ? (
    <Typography flat as="span" size="xs" type={type}>
      {children}
    </Typography>
  ) : (
    children
  );
};

const errorClasses = [
  "focus:outline-none",
  "border-red-500",
  "text-red-500",
  "placeholder-red-400",
  "focus:ring-red-500",
  "focus:border-red-500",

  "hover:border-red-400",
  "dark:hover:border-red-500",
  "dark:border-red-400",
  "dark:text-red-400",
  "dark:placeholder-red-300",
  "dark:focus:ring-red-400",
  "dark:focus:border-red-400",
];

const heightSizes = {
  xs: "h-5",
  sm: "h-6",
  md: "h-8",
  lg: "h-10",
  xl: "h-11",
};

const paddingSizes = {
  // xs: 'px-1.5 py-0.5',
  xs: "px-1.5",
  // sm: 'px-1.5 py-1',
  sm: "px-1.5 py-1",
  // md: 'px-2 py-1',
  md: "px-2 py-1",
  // lg: 'px-3 py-1',
  lg: "px-2 py-1",
  // xl: 'px-3.5 py-2',
  xl: "px-2.5 py-1.5",
};

const fontSizes = {
  xs: "text-xs",
  sm: "text-xs",
  md: "text-sm",
  lg: "text-base",
  xl: "text-lg",
};

const lineHeights = {
  xs: "leading-5",
  sm: "leading-6",
  md: "leading-8",
  lg: "leading-10",
  xl: "leading-10",
};

const Input = React.forwardRef<HTMLInputElement, IInput>((props, ref) => {
  const {
    className = "",
    error,
    label,
    labelFor,
    message,
    block,
    isOptional,
    hint,
    loading,
    allowClear,
    size,
    orientation = "vertical",
    footer,
    register = {},
    labelProps = {},
    hintProps = {},
    messageProps = {},
    required,
    filter,
    insetMaxLength,
    ...rest
  } = props;

  // @ts-ignore
  const [length, setLength] = React.useState(
    (typeof rest.value == "string" && rest.value?.length) ||
      (typeof rest.defaultValue == "string" && rest.defaultValue?.length) ||
      0
  );

  let style = {};

  let inputClasses = `bg-white dark:bg-transparent ${
    !error
      ? "border-gray-300 hover:border-gray-400 dark:border-gray-600 dark:hover:border-gray-700"
      : ""
  } focus:ring-gray-200 dark:text-gray-200 dark:focus:ring-gray-700`;

  const fontSize = fontSizes[size || "md"];
  const paddingSize = paddingSizes[size || "md"];
  const heightSize = heightSizes[size || "md"];
  const lineHeight = lineHeights[size || "md"];

  if (rest.disabled || rest.readOnly) {
    // cursor-not-allowed?
    inputClasses = `bg-gray-200 dark:bg-gray-700 ${
      !error ? "border-gray-300 dark:border-gray-800" : ""
    } dark:text-gray-200 ${rest.disabled ? "cursor-not-allowed" : ""}`;
  } else {
    // inputClasses += ' focus:ring-1 focus:ring-offset-1 focus:ring-offset-transparent focus:ring-gray-300 dark:focus:ring-gray-600 focus:outline-none';
  }

  const updateLength = React.useCallback(
    throttle((value) => {
      setLength(value?.length || 0);
    }, 200),
    []
  );

  return (
    <div
      className={classNames([
        "relative",
        block ? "w-full" : "max-w-fit",
        orientation == "horizontal" ? "flex space-x-2" : "",
      ])}
    >
      {orientation == "horizontal" && label && (
        <div className="flex items-center">
          {label && (
            <Label labelFor={labelFor || label} {...maybeObject(labelProps)}>
              {label}
            </Label>
          )}
        </div>
      )}
      {orientation == "vertical" &&
        (label || hint || isOptional || required) && (
          <div className="flex items-center justify-between mb-1">
            <div>
              {label && <Label labelFor={labelFor || label}>{label}</Label>}
            </div>
            <div>
              {(hint || isOptional || required) && (
                // <Hint type={required ? 'error' : undefined}>
                <Hint>
                  {hint ||
                    (required && "Required") ||
                    (isOptional && "Optional")}
                </Hint>
              )}
            </div>
          </div>
        )}
      <div
        className={`relative rounded-sm ${
          block && orientation == "horizontal" ? "flex-1" : ""
        }`}
      >
        {rest.type == "search" && (
          <div className="absolute top-0 bottom-0 flex items-center justify-center left-1">
            <svg
              className="w-4 h-4 text-gray-600"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
            </svg>
          </div>
        )}
        <input
          tabIndex={rest.disabled || rest.readOnly ? -1 : 0}
          className={classNames(
            "no-print",
            "block",
            "rounded-sm",
            "border",
            inputClasses,
            block ? "w-full" : "",
            error ? "pr-10" : "",
            ...(error ? errorClasses : []),
            fontSize,
            lineHeight,
            heightSize,
            paddingSize,
            className,
            insetMaxLength ? "pr-12" : "",
            rest.type == "search" ? "pl-6" : ""
          )}
          ref={ref}
          style={style}
          autoComplete="off"
          // required={required}
          type={allowClear ? "search" : rest.type ? rest.type : "text"}
          {...register}
          {...rest}
          onChange={(e) => {
            if (filter) {
              if (typeof filter == "function" && !filter(e)) return false;
              if (typeof filter == "string") {
                const pattern = new RegExp(filter);
                if (!pattern.test(e.target.value)) return false;
              }
            }
            rest.onChange && rest.onChange(e);
            register.onChange && register.onChange(e);
            if (!!rest.maxLength) {
              updateLength(e?.target?.value);
            }
          }}
        />
        {!loading && insetMaxLength && !!rest.maxLength && (
          <div className={classNames(["absolute right-2 top-0"])}>
            <p
              className={classNames([
                "text-xs",
                lineHeight,
                length > rest.maxLength
                  ? "text-red-500 dark:text-red-400"
                  : "text-gray-400 dark:text-gray-500",
              ])}
            >
              {length}/{rest.maxLength}
            </p>
          </div>
        )}
        {loading && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <FontAwesomeIcon aria-hidden="true" icon={faSpinnerThird} spin />
          </div>
        )}
        {error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            {/* @ts-ignore */}
            <FontAwesomeIcon
              className="text-red-500 dark:text-red-400"
              aria-hidden="true"
              icon={faCircleExclamation}
            />
          </div>
        )}
      </div>
      {footer === null ? null : typeof footer === "boolean" &&
        !footer ? null : typeof footer == "function" ? (
        footer({ message })
      ) : !!(message || rest.maxLength || error) ? (
        <div className="flex justify-between mt-0.5">
          <div>
            {(typeof error == "string" || message) && (
              <p
                className={classNames([
                  "text-xs",
                  error
                    ? "text-red-500 dark:text-red-400"
                    : "text-gray-600 dark:text-gray-300",
                ])}
              >
                {typeof error == "string" ? error : message}
              </p>
            )}
          </div>
          <div>
            {!insetMaxLength && !!rest.maxLength && (
              <p
                className={classNames([
                  "text-xs",
                  length > rest.maxLength
                    ? "text-red-500 dark:text-red-400"
                    : "text-gray-400 dark:text-gray-500",
                ])}
              >
                {length}/{rest.maxLength}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div style={{ minHeight: 16 }} />
      )}
    </div>
  );
}) as ICompoundedInputComponent;

const TextArea = React.forwardRef<HTMLInputElement, ITextArea>((props, ref) => {
  const {
    className = "",
    error,
    label,
    labelFor,
    message,
    required,
    block,
    isOptional,
    hint,
    resize,
    loading,
    fill,
    size,
    footer,
    register = {},
    labelProps = {},
    hintProps = {},
    messageProps = {},
    filter,
    style = {},
    ...rest
  } = props;

  // @ts-ignore
  const [length, setLength] = React.useState(
    (typeof rest.value == "string" && rest.value?.length) ||
      (typeof rest.defaultValue == "string" && rest.defaultValue?.length) ||
      0
  );
  const [inputRef, setInputRef] = React.useState<any>();

  let inputClasses =
    "bg-white dark:bg-transparent border-gray-300 hover:border-gray-400 dark:border-gray-700 dark:hover:border-gray-600";
  // let inputClasses = 'bg-white dark:bg-transparent border-gray-300 hover:border-gray-400 dark:border-gray-700 dark:hover:border-gray-600 focus:ring-gray-200 dark:text-gray-200 dark:focus:ring-gray-600';

  let sizeClasses: string[] = ["py-1.5", "px-2", "text-sm"];

  if (size == "sm") {
    sizeClasses = ["px-2", "py-1.5", "text-xs"];
  } else if (size == "lg") {
    sizeClasses = [
      "p-2",
      // 'text-md',
    ];
    style.fontSize = "85%";
  } else if (size == "xl") {
    sizeClasses = ["px-3", "py-2.5", "text-lg"];
  }

  if (rest.disabled || (resize !== undefined && !resize)) {
    style.resize = "none";
  }

  if (rest.disabled || rest.readOnly) {
    // cursor-not-allowed?
    inputClasses = `bg-gray-200 dark:bg-gray-700 border-gray-300 dark:border-gray-800 dark:text-gray-200  ${
      rest.disabled ? "cursor-not-allowed" : ""
    }`;
  } else {
    // inputClasses += ' focus:ring-1 focus:ring-offset-1 focus:ring-offset-transparent focus:ring-gray-300 dark:focus:ring-gray-600 focus:outline-none';
  }
  React.useEffect(() => {
    // @ts-ignore
    if (ref && ref?.current?.value) {
      // @ts-ignore
      ref.current.setSelectionRange(
        // @ts-ignore
        ref.current.value.length,
        // @ts-ignore
        ref.current.value.length
      );
    }
  }, [ref]);

  const updateLength = React.useCallback(
    throttle((value) => {
      setLength(value?.length || 0);
    }, 200),
    []
  );

  return (
    <div
      className={classNames(
        block ? "w-full" : "w-min",
        fill ? "h-full flex flex-col flex-1" : ""
      )}
    >
      {(label || hint || isOptional || required) && (
        <div className="flex items-center justify-between mb-1">
          <div>
            {label && <Label labelFor={labelFor || label}>{label}</Label>}
          </div>
          <div>
            {(hint || isOptional || required) && (
              <Hint type={required ? "error" : undefined}>
                {hint || (required && "Required") || (isOptional && "Optional")}
              </Hint>
            )}
          </div>
        </div>
      )}
      <div
        // className='relative rounded-sm'
        className={classNames([
          "relative rounded-sm",
          fill ? "flex flex-1" : "",
        ])}
      >
        <textarea
          className={classNames(
            "block",
            "rounded-sm",
            "border",
            fill ? "flex-1" : "",
            // 'focus:ring-1',
            // 'ring-offset-transparent',
            // 'focus:ring-offset-1',
            // 'focus:outline-none',
            inputClasses,
            block ? "w-full" : "w-auto",
            error ? "pr-10" : "",
            ...(error ? errorClasses : []),
            ...sizeClasses,
            className
          )}
          style={style}
          // required={required}
          {...register}
          {...rest}
          value={isEmpty(rest.value) ? undefined : rest.value}
          ref={ref}
          onChange={(e) => {
            if (filter) {
              if (typeof filter == "function" && !filter(e)) return false;
              if (typeof filter == "string") {
                const pattern = new RegExp(filter);
                if (!pattern.test(e.target.value)) return false;
              }
            }
            register.onChange && register.onChange(e);
            rest.onChange && rest.onChange(e);
            if (!!rest.maxLength) {
              updateLength(e?.target?.value);
            }
          }}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            {/* @ts-ignore */}
            <FontAwesomeIcon
              className="text-red-500 dark:text-red-400"
              aria-hidden="true"
              icon={faCircleExclamation}
            />
          </div>
        )}
      </div>
      {footer === null
        ? null
        : typeof footer == "function"
        ? footer({ message })
        : !!(message || rest.maxLength) && (
            <div className="flex justify-between mt-0.5">
              <div>
                {message && (
                  <p
                    className={classNames([
                      "text-xs",
                      error
                        ? "text-red-500 dark:text-red-400"
                        : "text-gray-600 dark:text-gray-300",
                    ])}
                  >
                    {message}
                  </p>
                )}
              </div>
              <div>
                {!!rest.maxLength && (
                  <p
                    className={classNames([
                      "text-xs",
                      length > rest.maxLength
                        ? "text-red-500 dark:text-red-400"
                        : "text-gray-400 dark:text-gray-500",
                    ])}
                  >
                    {length}/{rest.maxLength}
                  </p>
                )}
              </div>
            </div>
          )}
    </div>
  );
});

//@ts-ignore
Input.TextArea = TextArea;
//@ts-ignore
Input.Hint = Hint;
//@ts-ignore
Input.Label = Label;

export default Input;
